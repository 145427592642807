/* Common SCSS Variables */

$htmlBackground: #fff;
$pageBackground: #fff;

/* font families */
$fontBodyFamily:  "News Cycle", Helvetica, sans-serif;
$fontHeaderFamily: "News Cycle", Helvetica, sans-serif;
$fontSubheaderFamily: "News Cycle", Helvetica, sans-serif;
$fontMenuFamily: "News Cycle", Helvetica, sans-serif;


/* theme main colors */
$themeMainLight: #8b8b8b; /* medium grey */
$themeMainMedium1: #cb623c; /* sienna */
$themeMainMedium2: #569496;  /* teal */
$themeMainDark: #4D81C0; /* cobalt blue */
$themeMainVeryDark: #171717; /* almost black */
$themeMainOffset: #548554; /* powder green */




/* font colors */
$fontPageHeading: #569496;  /* teal */
$fontPageSubheading: #cb623c; /* sienna */
$fontSidebarHeading: #444; /* dark grey */


/* content highlights */
$contentHighlight1: #65af34; /* bright green */
$contentHighlight2: #3f81a3; /* cobalt blue */
$contentHighlight3: #67937d; /* powder green */
$contentHighlight4: #669ea1; /* teal */
$contentHighlight5: #674e47; /* brown */


/* buttons, controls */
$buttonGradientLight: #669ea1; /* light teal */
$buttonGradientMedium: #7193a7; /* medium teal */
$buttonGradientDark: #597f91; /* dark teal */
$inputFieldBack: #e7e7e7; /* light Grey */





/* end SCSS Variables */