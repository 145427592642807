 /* use on teasers and full nodes. 
 *  
 *  Markup has quote in blockquote tags.
 *  All other info is wrapped in a footer
 */
@mixin node-testimonial ($testMargin) {
  blockquote {
	  font-style: italic;
	  margin-left: $testMargin;
	  text-indent: 2.2em;
	  background: url(/sites/all/themes/pure7/images/quotes.png) left top no-repeat;
	  + footer:before {
		  content: "~ ";
		  margin-left: ($testMargin + 2.2);
		  font-size: small;
	  }
  } 
  span:before {
		content: " -- ";		  
	}
}

/* cross-browser border radius */
@mixin rounded-border($radii) {
  -webkit-border-top-left-radius: $radii;
  -webkit-border-top-right-radius: $radii;
  -webkit-border-bottom-right-radius: $radii;
  -webkit-border-bottom-left-radius: $radii;

  -moz-border-radius-topleft: $radii;
  -moz-border-radius-topright: $radii;
  -moz-border-radius-bottomright: $radii;
  -moz-border-radius-bottomleft: $radii;

  border-top-left-radius: $radii;
  border-top-right-radius: $radii;
  border-bottom-right-radius: $radii;
  border-bottom-left-radius: $radii;
}