/* This file will hold styles for the mobile version of your website (mobile first). */
/* This also can include ANY global CSS that applies site-wide. Unless overwritten by a more specific style rule, CSS declarations in global.css will apply site-wide. */
/* PureWebMedia - March 2013 */

/* Include Default Variables & Mixins */
@import "variables.scss";
@import "mixins.scss"; 
/* End Variables & Mixins */

/*------------------------------------------------
[Table of contents]
0. Reset
1. Global
  1.1. Zones
  1.2. Regions
2. Section header
  2.1. Zone user
    2.1.1. Region user first
    2.1.2. Region user second
  2.2. Zone branding
  2.3. Zone menu
  2.4. Zone header
3. Section content
  3.1. Zone preface
  3.2. Zone content
    3.2.1. Sidebar first
    3.2.2. Content
    3.2.3. Sidebar second
  3.3. Zone postscript
4. Section footer
  4.1. Zone footer
  4.2. Zone footer2
5. Commerce specific
  5.1. Search & Collection page
  5.2. All products page
  5.3. Product page
  5.4. Checkout process
6. Kickstart specific
  6.1. Homepage elements
7. Blog
8. Comments
9. User account
------------------------------------------------*/

/**************************************************
* --
/* @group 0. Reset
* --
**************************************************/


html {
	background-color: $htmlBackground;	
}

body {
  font: normal 18px/1.6em $fontBodyFamily;
  margin: 0;
  color: #444;
  background-color: $pageBackground;
}

.clearfix:after { /* gets rid of annoying space at the bottom */
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.container-12,
.container-16 {
    background-color: $pageBackground;
}

a {
  outline: 0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#admin-menu * { /* for Admininstration Menu module.  */
     font-size: 11px;
     font-weight: bold;
}

#admin-menu, #admin-menu .dropdown {
    line-height: 1.8em;
}

figure {
  margin: 0;
}

h1, h2, h3, h4 {
  line-height: 1.3em;
}

input[type="search"] { /* stop webkit browsers from styling the search field */
  -webkit-appearance: textfield;
}



/* Push footer to bottom */

 html, body {height: 100%; margin: 0;}
#page {display: table; width: 100%;}
html>/**/body #page {height: 100%;}
*:first-child+html #page {height: auto;}
.section {display: table-row;}
html>/**/body .section-content {height: 100%;}
*:first-child+html body .section-content {height: auto;}

/* @end Reset */



/* @group *********************************** HEADER */


/* @group +++++++++++++++ USER */

/* @end USER */



/* @group +++++++++++++++ BRANDING */

/* @end BRANDING */



/* @group +++++++++++++++ MENU  */

.region-menu-inner {
	margin-bottom: 10px;
}

.block-block-8 { /* pwm mobile logo */
	display: block;
	margin-left: 20px;
	img {
		width: 114px;
		height: 69px;
	}
}

.block-superfish-1 {
	float: left;
	margin-left: 200px;
	margin-top: -30px;
	select {
		min-width: 100px;
	}
}

ul.menu li { /* override system default */
	padding: 0;
	margin: 0;
}
ul.sf-menu {
	li {
	&.first {
		padding-left: 0
	}
	&.last {
		padding-right: 0;
	}
		a {
			text-transform: uppercase;	
			color: $fontSidebarHeading;
			font-weight: bold;
		}
	}
}


/* @end MENU */



/* @group +++++++++++++++ HEADER First */

/* @end HEADER First */



/* @group +++++++++++++++ HEADER Second */

/* @end HEADER Second */


/* @end HEADER */






/* @group *********************************** SECTION */

/* @group --------------------- ZONE PREFACE */


/* @group --------------------- ZONE PREFACE */




.zone-preface-wrapper { /* hide the whole rockets banner */
	display: none;
}


/* @end ZONE PREFACE */



/* @group +++++++++++++++ CONTENT */

.zone-content {
  padding: 20px 0 50px 0;
}

.region-content-inner {
  padding-left: 5%;
  padding-right: 20px;
  .item-list ul.pager {
  	margin-top: 30px;
    li { /* boxes around the pager numbers for paged items */
	    padding: 0.2em;
	    a { 
	      background-color: #f1f1f1;
	      border: 1px solid #C0C0C0;
	      -moz-border-radius: 4px 4px 4px 4px;
	      border-radius: 4px 4px 4px 4px;
	      color: #666666;
	      font-size: 13px;
	      padding: 2px 8px;
	      text-decoration: none;
	    } 
	    a:hover {
	      background-color: lighten($themeMainOffset, 30%);
	    }  
	  } 
	}
  h2, h3, h4, h5 {
  	margin: 1em 0 0.5em 0;
  }
  h1 {
    color: $themeMainMedium1;    font-size: 40px; 
    font-weight: bold;
  }
  h2, h2 a {
    color: darken($themeMainDark, 5%);
    font-size: 26px;
    font-weight: bold;
  }
  .view-teasers-as-articles h2 {
    font-size: 24px;
    color: grey;
  } 
  h3, h3 a {
    color: lighten($themeMainVeryDark, 10%);
    font-size: 22px;
    font-weight: bold;
  }
  h4, h4 a {
    /* color: $; */
    font-size: 18px;
    font-weight: normal;
  }
  h5, h5 a {
    /* color: $; */
    font-size: 16px;
    font-weight: normal;
  }
  a {
    color: $fontSidebarHeading;
    font-weight: bold;
  }
  pre, code {
		font-size: 14px;
		white-space: pre-wrap;       /* css-3 */
		white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
		white-space: -pre-wrap;      /* Opera 4-6 */
		white-space: -o-pre-wrap;    /* Opera 7 */
		word-wrap: break-word;       /* Internet Explorer 5.5+ */
		line-height: 1.3em;
  }
  code {
  	background-color: #777;
  	padding: 2px;
  	color: #ffffff;
  }
  time {
	  font-size: smaller;
	  display: block;
	  margin: 0.5em 0;
  }
  .floatleft,
  img[style*="left"] {
    clear: both;
    float: left;
    margin: 4px 10px 4px 0;
  }  
  .floatright,
  img[style*="right"] {
    clear: both;
    float: right;
    margin: 4px 0 4px 10px;
  }
  ol ol { /* embedded ordered list styles */
	   list-style-type:lower-alpha;
	   ol {
		 	list-style-type:lower-roman;  
	   }
  }
  .block-print-links {
	  float: right;
	  font-size: small;
  }
  .field-tags {
    background-image: url("/sites/all/themes/pure7/images/tags.png");
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 30px;
    font-size: 14px;
	}
	.teaser-row {
		padding-bottom: 2.5em;
	}
	 /* Services Page */
  .web-services-icons { 
		display: none; 
  }
  /* Project Page -- Isotope */
  .isotope-options {
  	background-color: transparent;
  	ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			margin-bottom: 40px;
			li {
				display: inline;
				a {
					text-decoration: none;
					padding: .2em 1em;
					text-transform: uppercase;
					font-size: 14px;
					&.selected {
						background: lighten($themeMainOffset, 30%);
					}
					&:hover {
						background: lighten($themeMainOffset, 40%);
						-webkit-transition:all 0.4s ease;
						-moz-transition:all 0.4s ease;
						-o-transition:all 0.4s ease;
						-ms-transition:all 0.4s ease;
						transition:all 0.4s ease;
					}
				}			
			}
		}
	}
	
	.isotope-element {
		width: 200px;
		height: 150px;
		img {
			width: 220px;
		}
	}
	
	.notice-level-1 {
		  padding: 0.5em 1.5em;
		  font-size: 0.9em;
		  @include rounded-border(10px);
		background: -moz-linear-gradient(top, rgba(255,197,120,0.75) 0%, rgba(251,157,35,0.75) 100%); /* FF3.6+ */
background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,197,120,0.75)), color-stop(100%,rgba(251,157,35,0.75))); /* Chrome,Safari4+ */
background: -webkit-linear-gradient(top, rgba(255,197,120,0.75) 0%,rgba(251,157,35,0.75) 100%); /* Chrome10+,Safari5.1+ */
background: -o-linear-gradient(top, rgba(255,197,120,0.75) 0%,rgba(251,157,35,0.75) 100%); /* Opera 11.10+ */
background: -ms-linear-gradient(top, rgba(255,197,120,0.75) 0%,rgba(251,157,35,0.75) 100%); /* IE10+ */
background: linear-gradient(to bottom, rgba(255,197,120,0.75) 0%,rgba(251,157,35,0.75) 100%); /* W3C */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfffc578', endColorstr='#bffb9d23',GradientType=0 ); /* IE6-9 */
	}
	
  /* Project Page -- Individual */
  .node-project {
	  .field-project-monitor-images {
		  float: right;
		  margin-left: 30px;
	  }
	  div.field-body + a {
			background-image: url("/sites/all/themes/pure7/images/link.png");
	    background-position: left center;
	    background-repeat: no-repeat;
	    font-size: 14px;
	    padding-left: 30px;
	  }
  }
  /* Project Page - slideshow */
  .view-portfolio-slideshow {
  	position: relative;
	  .item-list { /* reposition the pager */
		  li {
			  display: none; /* hide all superflous pager items */
		  }
		  li.pager-previous,
		  li.pager-next {
			  display: block;
			  position: absolute;
			  top: 0;
		  }
		  li.pager-previous {
				right: 70px	  
		  }
		  li.pager-next {
		  	right: 10px;
		  }
	  }
  }
  /* Testimonials */
  .node-testimonial {
	  @include node-testimonial(0em);
  }
  /* Contact Page */
  .contact-map {
	  float: right;
  }
  #node-page-73 p span {
    float: left;
    margin-right: 17px;
    text-align: right;
    width: 50px;
  }
}




/* !------------- Front page */



/* @end CONTENT */



/* @group +++++++++++++++ SIDEBAR First */

/* @end SIDEBAR First */



/* @group +++++++++++++++ SIDEBAR Second */

.region-sidebar-second {
  .block {
    margin: 15px 0;    
    font-size: 16px;
    color: lighten($themeMainVeryDark, 25%);
    a {
	    color: lighten($themeMainVeryDark, 25%);
	    &.active {
		  	font-weight: bold; 
	    }
    }
  }
  .block-inner {
    padding: 10px;
    position: relative;
  }
  h2, h2 a {
    /* color: $; */
    font-size: 20px;
    margin-top: 0;
  }
  h3, h3 a {
    /* color: $; */
    font-size: 18px;
  }
  ul {
    list-style-type: square;
  }
  .more-link {
    font-size: 14px;
    font-weight: bold;
  }
  /* Testimonials */
  .node-testimonial {
	  @include node-testimonial(2em);
  }
}

/* @end SIDEBAR Second */




/* @group +++++++++++++++ POSTSCRIPT First */

/* @end POSTSCRIPT First */



/* @group +++++++++++++++ POSTSCRIPT Second */

/* @end POSTSCRIPT Second */



/* @group +++++++++++++++ POSTSCRIPT Third */

/* @end POSTSCRIPT Third */



/* @group +++++++++++++++ POSTSCRIPT Fourth */

/* @end POSTSCRIPT Fourth */





/* @end SECTION */




/* @group *********************************** FOOTER */

.section-footer, .zone-footer {
	background: url("/sites/all/themes/pure7/images/speckled.png") repeat scroll 0 0 $themeMainVeryDark;
}

.zone-footer {
	padding-top: 25px;
	padding-bottom: 10px;
}

/* @group +++++++++++++++ FOOTER First */

.region-footer-first-inner {
	.block {
		margin: 0;
		font-size: 14px;
		line-height: 1.5em;
		color: #777777;
	}
	.content {
		a {
			color: #666666;
			&.active, &:hover {
				color: lighten($themeMainDark, 10%);
			}
		}
	}
	h2 {
		margin-top: 0;
		line-height: 0.8em;
		color: $fontPageSubheading;
	}
	.pwm-footer { /* Pure Web Media block */
		ul.web-apps {
			display: inline;
	    list-style-type: none;
	    padding-left: 0;
	    li {
				display: inline;
				list-style-type: none;
				padding-right: 16px;
	    }
		}
		img.footer-logo {
			width: 76px;
			height: 46px;
		}
	}
	.block-menu { /* Menu block  */
		display: none;
		ul {
			padding-left: 1em;
			-moz-column-count:2; /* Firefox */
			-webkit-column-count:2; /* Safari and Chrome */
			column-count:2;	
		}
		li:nth-child(4) { /* hide home link used for pwm icon */
			display: none;
		} 
	}
	.social-media-block { /* Social Media block  */
		display: none;
		a { 
    	color: #666666;
			text-decoration: none;
			transition: all 0.1s linear 0s;
    }
    .social-network {
			background: url("../images/social.png") no-repeat scroll left top transparent;
			cursor: pointer;
			display: inline-block;
			height: 33px;
			margin: 3px 10px 12px 0;
			background-position-y: -42px; 
			-webkit-transition:all 0.2s ease;
			-moz-transition:all 0.2s ease;
			-o-transition:all 0.2s ease;
			-ms-transition:all 0.2s ease;
			transition:all 0.2s ease;
			cursor:pointer;
			width: 33px;
		}
		a.social-network {
		    text-indent: -10000em;
		}
		.social-network.facebook {
		    background-position: -210px -42px;
		}
		.social-network.feed {
		    background-position: -252px -42px;
		}
		.social-network.linkedin {
		    background-position: -504px -42px;
		}
		.social-network.skype {
		    background-position: -756px -42px;
		}
		.social-network.stumbleupon {
		    background-position: -798px -42px;
		}
		.social-network.twitter {
		    background-position: -882px -42px;
		}
		.social-network.facebook:hover, .active .social-network.facebook {
		    background-position: -210px 0;
		}
		.social-network.feed:hover, .active .social-network.feed {
		    background-position: -252px 0;
		}
		.social-network.gplus:hover, .active .social-network.gplus {
		    background-position: -378px 0;
		}
		.social-network.linkedin:hover, .active .social-network.linkedin {
		    background-position: -504px 0;
		}
		.social-network.skype:hover, .active .social-network.skype {
		    background-position: -756px 0;
		}
		.social-network.twitter:hover, .active .social-network.twitter {
		    background-position: -882px 0;
		}
		.social-network.youtube:hover, .active .social-network.youtube {
		    background-position: -1050px 0;
		}
	}
	.pwm-contact-block { /* contact address block */
		display: none;
	}
}











/* @end FOOTER First */


/* @group +++++++++++++++ FOOTER Second */

/* @end FOOTER Second */


/* @end FOOTER */